import {useWindowSize} from '@vueuse/core'
import {Ref} from "vue";

const sm = 640;
const md = 768;
const lg = 1024;
const xl = 1280;
const two_xl = 1536;
const tree_xl = 1920;


let {width} = useWindowSize();



export function screenLargerThan(size: string|number = 'md') {

    if(typeof size === 'number') {
        return width.value >= size;
    }

    switch (size) {
        case 'sm':
            return width.value >= sm;
        case 'md':
            return width.value >= md;
        case 'lg':
            return width.value >= lg;
        case 'xl':
            return width.value >= xl;
        case '2xl':
            return width.value >= two_xl;
        case '3xl':
            return width.value >= tree_xl;
        default:
            return width.value >= md;
    }
}

export function screenSize(): '3xl'|'2xl'|'xl'|'lg'|'md'|'sm'|'xs' {

    if(width.value >= tree_xl){
        return '3xl';
    }
    if(width.value >= two_xl){
        return '2xl';
    }
    if(width.value >= xl){
        return 'xl';
    }
    if(width.value >= lg){
        return 'lg';
    }
    if(width.value >= md){
        return 'md';
    }
    if(width.value >= sm){
        return 'sm';
    }
    return 'xs';
}

export function screenSmallerThan(size: string|number = 'md') {
    return !screenLargerThan(size);
}
